@if (uid) {
    <h1>Welcome back!</h1>
    <button (click)="logout()">Logout</button>
} 
@else {
    <h1>Please sign in!</h1>
    <form>
        <input placeholder="email" type="email" [ngModel]="email()" (ngModelChange)="email.set($event)" name="email">
        <input placeholder="password" type="password" [ngModel]="password()" (ngModelChange)="password.set($event)"
            name="password">
    </form>
    <button (click)="login()">Login</button>
}