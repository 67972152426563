import { Component, OnInit, signal } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { Auth, signInWithEmailAndPassword, getAuth, onAuthStateChanged } from '@angular/fire/auth';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  standalone: true,
  imports: [FormsModule],
  templateUrl: './login.component.html',
  styleUrl: './login.component.css'
})
export class LoginComponent implements OnInit {
  email = signal('');
  password = signal('');
  uid = '';

  constructor(private afAuth: Auth, private router: Router) {
  }

  ngOnInit(): void {
    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {
      if (user) {
        this.uid = user.uid;
        console.log('login', user.uid);
      } else {
        this.uid = '';
        console.log('logout');
        this.router.navigate(['login']);
      }
    });
  }

  async login() {
    try {
      const t = await signInWithEmailAndPassword(this.afAuth, this.email(), this.password());
      if (t) {
        this.router.navigate(['search']);
      }
      console.log(t);
    } catch (e) {
      alert(e);
    }
  }

  async logout() {
    await this.afAuth.signOut();
  }
}
