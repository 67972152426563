import { Component, input, model, signal } from '@angular/core';

@Component({
  selector: 'app-customize',
  standalone: true,
  imports: [],
  templateUrl: './customize.component.html',
  styleUrl: './customize.component.css'
})
export class CustomizeComponent {

  size = signal('small');
  isEmpty = true;
  isCoverUp = signal(false);
  fla = input('');
  topping = model('');
  readonly toppingCount = [...Array(18)];
  readonly sealLineCount = [...Array(18)];

  coverUp() {
    this.isCoverUp.set(true);

    setTimeout(() => {
      this.isCoverUp.set(false);
    }, 750);
  }
}
