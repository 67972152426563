import { ApplicationConfig, importProvidersFrom } from '@angular/core';
import { provideRouter } from '@angular/router';

import { routes } from './app.routes';
import { provideClientHydration } from '@angular/platform-browser';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { getAuth, provideAuth } from '@angular/fire/auth';

export const appConfig: ApplicationConfig = {
  providers: [provideRouter(routes), provideClientHydration(), provideAnimationsAsync(), importProvidersFrom(provideFirebaseApp(() => initializeApp({ "projectId": "bubble-jingle", "appId": "1:675610072556:web:b87a53accf505c84b51cc9", "storageBucket": "bubble-jingle.appspot.com", "apiKey": "AIzaSyCr7GHt73VFNx8y7zsSX26OuAuJ9aAF7Es", "authDomain": "bubble-jingle.firebaseapp.com", "messagingSenderId": "675610072556" }))), importProvidersFrom(provideAuth(() => getAuth()))]
};
