import { Routes } from '@angular/router';
import { CustomizeComponent } from './customize/customize.component';
import { SearchComponent } from './search/search.component';
import { PlaygroundComponent } from './playground/playground.component';
import { LoginComponent } from './login/login.component';

export const routes: Routes = [
    { path: 'search', component: SearchComponent },
    { path: 'playground', component: PlaygroundComponent },
    { path: 'login', component: LoginComponent },
    // { path: 'customize', component: CustomizeComponent },
    { path: '',   redirectTo: '/search', pathMatch: 'full' }
];
