<p class="options" [class.hide-options]="!fla()">
    <button (click)="size.set('small')" [disabled]="size() == 'small'">Regular</button>
    <button (click)="size.set('large')" [disabled]="size() == 'large'">Large</button>
    <span>&nbsp;</span>
    <button (click)="topping.set('boba')" [disabled]="topping() == 'boba'">Boba</button>
    <button (click)="topping.set('red-bean')" [disabled]="topping() == 'red-bean'">Red bean</button>
    <button (click)="topping.set('jelly')" [disabled]="topping() == 'jelly'">Jelly</button>
</p>

<p class="options" [class.hide-options]="!fla()">
    <button class="purchase-button" [class]="fla()" (click)="coverUp()">Get it ($12)</button>
</p>

<section [class.shake-it]="isCoverUp()">
    <div class="cup-cover-container" [class.is-covered]="isCoverUp()">
        <div class="straw-outer">
            <div class="straw"></div>
        </div>
        <div class="cup-cover-outer-wrapper">
            <div class="cup-cover-inner-wrapper">
                <div class="cup-cover" [class]="[size(), fla()]"></div>
            </div>
        </div>
        <div class="cup-seal" [class]="[size()]">
            @for (item of sealLineCount; track item;) {
                <div class="line"></div>
            } 
        </div>
    </div>
    <div class="cup-outer-wrapper">
        <div class="cup-inner-wrapper">
            <div class="cup" [class]="[size()]">
                <div class="flavor" [class]="fla()" [class.empty]="isEmpty"></div>
                <div class="topping">
                    @for (item of toppingCount; track item;) {
                        <div [class]="topping()"></div>
                    } 
                </div>
            </div>
        </div>
    </div>
</section>
