import { Injectable } from "@angular/core";
import { Flavor } from "../shared/utils";

@Injectable({
  providedIn: "root",
})
export class TeaService {
  constructor() {}

  private teaList: Flavor[] = [
    {
      id: "original",
      name: "Classic Roasted Oolong",
      flag: "/assets/images/original.avif",
      topping: "boba",
    },
    {
      id: "matcha",
      name: "Brown Sugar Matcha",
      flag: "/assets/images/matcha.avif",
      topping: "red-bean",
    },
    {
      id: "mango",
      name: "Fresh Mango Tango",
      flag: "/assets/images/mango.avif",
      topping: "jelly",
    },
    {
      id: "taro",
      name: "Handcrafted Taro",
      flag: "/assets/images/taro.avif",
      topping: "boba",
    },
  ];

  async getList(): Promise<Flavor[]> {
    // const queryparam = '?lvl=2';
    const queryparam = "?lvl=1";
    // const queryparam = '';
    const url = "https://hello-io-24.deno.dev/"; //'https://bubble-tea-list.glitch.me/api/list'
    const req = await window.fetch(url + queryparam);
    const flavor = await req.json();

    if (req.status == 404) throw new Error(flavor.error);
    return flavor;
  }
}
