<form>
    <h1 for="">Craving fruity, creamy, or classic bubble tea?</h1>
    <mat-form-field>
        <input matInput aria-label="Flavor" (focus)="loadData()" [matAutocomplete]="auto" [formControl]="flavorCtrl"
            #trigger="matAutocompleteTrigger">
        @if (flavorCtrl.value) {
        <button matSuffix mat-icon-button aria-label="Clear" (click)="clearSelection()">
            <mat-icon>close</mat-icon>
        </button>
        }

        <mat-autocomplete #auto="matAutocomplete">
            @if(status == 'loading' || status == 'error') {
                <mat-option [value]="">
                    <span class="status" [class.error]="status == 'error'">{{statusMessage}}</span>
                </mat-option>
            } @else {
                @for (flavor of filteredList(); track flavor.id) {
                    <mat-option [id]="flavor.id" [value]="flavor.name">
                        <div class="item">
                            <img [alt]="flavor.name" [src]="flavor.flag">
                            <span>{{flavor.name}}</span>
                        </div>
                    </mat-option>
                }
            }
        </mat-autocomplete>
    </mat-form-field>
    <app-customize [fla]="selectedId()" [topping]="selectedTop() || ''"></app-customize>
</form>