import { Component, HostListener, ViewChild, signal, computed, AfterViewInit, OnInit } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatButtonModule } from '@angular/material/button';
import { AsyncPipe } from '@angular/common';
import { MatAutocompleteModule, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { RouterModule } from '@angular/router';
import { TeaService } from '../tea.service';
import { CustomizeComponent } from '../customize/customize.component';
import { toSignal } from '@angular/core/rxjs-interop';
import { Flavor } from '../../shared/utils';

@Component({
  selector: 'app-search',
  standalone: true,
  imports: [
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatAutocompleteModule,
    ReactiveFormsModule,
    MatSlideToggleModule,
    MatButtonModule,
    RouterModule,
    MatIconModule,
    AsyncPipe,
    CustomizeComponent,
  ],
  templateUrl: './search.component.html',
  styleUrl: './search.component.css'
})
export class SearchComponent implements AfterViewInit {
  flavorCtrl = new FormControl('');
  @ViewChild('trigger') trigger!: MatAutocompleteTrigger;

  list = signal<Flavor[]>([]);
  searchStr = toSignal(this.flavorCtrl.valueChanges, { initialValue: '' });
  status: 'loading' | 'error' | 'loaded' = 'loading';
  statusMessage = '';
  selectedId = signal('');
  selectedTop = computed(() => {
    if (!this.selectedId()) return '';
    return this.list().find(x => x.id === this.selectedId())?.topping || 'boba'
  });
  filteredList = computed(() => {
    if (!this.searchStr()) this.list().slice();

    const filterValue = this.searchStr()!.toLowerCase();
    return this.list().filter(flavor => flavor.name.toLowerCase().includes(filterValue));
  });

  constructor(public teaService: TeaService) {
  }

  ngAfterViewInit(): void {
    this.trigger.optionSelections.subscribe(x => {
      const id = this.trigger.autocomplete.options.find(x => x.selected)?.id || '';
      this.selectedId.set(id);
    });
  }

  async loadData() {
    if (this.list().length) return;

    try {
      this.status = 'loading';
      this.statusMessage = 'Loading...';

      const data = await this.teaService.getList();
      this.list.set(data);

      this.status = 'loaded';
      this.statusMessage = '';
    } catch (err: any) {
      this.status = 'error';
      this.statusMessage = err;
    }
  }

  public clearSelection() {
    this.flavorCtrl.setValue('');
    this.trigger?.autocomplete.options.forEach(option => {
      if (option.selected) option.deselect();
    });
  }

  @HostListener('window:blur')
  onBlur(): void {
    this.trigger?.closePanel();
  }
}
