import { Component } from '@angular/core';

@Component({
  selector: 'app-playground',
  standalone: true,
  imports: [],
  templateUrl: './playground.component.html',
  styleUrl: './playground.component.css'
})
export class PlaygroundComponent {

  constructor() {
    const getData = async (val: number) => {
        const response = await getData(1);
        console.log("response", response);
       }

      getData(2);
  }

}
